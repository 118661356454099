import Footer from '../../Components/Footer'
import { useTranslation } from 'react-i18next'
import Assistante from "../../Fichiers/Assistante.jpeg";
import { Avatar, Box, Button, CircularProgress, Container, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField } from '@mui/material';
import { Variants, motion } from "framer-motion"
import DrawerAppBar from '../../Components/DrawerAppBar';
import Menus from '../../Components/Menus';
import TitrePage from '../../Components/TitrePage';
import { Typography } from '@mui/material';
import { ArrowLeft, ArrowRight, Facebook, Home, Instagram, LinkedIn, LocationOn, Mail, PhoneAndroid, TextDecrease, TextFields } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import UploadFile from '../../Components/UploadFile';
import EllipseGauche from "../../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../../Fichiers/Ellipse 201.svg"
import illution_4 from "../../Fichiers/illution_4.svg";
import nousRejoindre from "../../Fichiers/nousRejoindre.jpg";
import APropos from "../../Database/APropos.json";
import { Carousel } from 'antd';
import StepperAgence from '../../Components/StepperAgence';
import StepperTempsFort from '../../Components/StepperTempsFort';

interface Props { }

function CreditBail(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [load, setLoad] = useState(false);


    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };


    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={'CREDIT BAIL'} lien='Accueil / Prêt / Crédit Bail' />
            <br /><br /><br /><br /><br /><br /><br /><br />



            <Box display={"flex"} lineHeight={1.6} textAlign={"justify"}>
                <img style={{ width: "5%", marginRight: "2%" }} draggable={false} src={EllipseGauche} alt="" />

                <Container maxWidth='lg'>

                    <Box display={{ xs: 'block', sm: 'flex' }} >

                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <Box>
                                <motion.div
                                    whileHover={{ scale: 1, rotate: 1 }}
                                    whileTap={{
                                        scale: 1,
                                        rotate: -1,
                                    }}
                                >
                                    <center><br /><br />
                                        <Box>
                                            <div><img style={{ borderRadius: "10%" }} width={300} height={200} src={"https://www.vienne-initiatives.org/wp-content/uploads/2023/08/est-ce-quouvrir-un-compte-lcl-est-pertinent-pour-un-particulier-aujourdhui.jpg"} alt="" /></div>
                                        </Box>
                                    </center>
                                </motion.div>
                            </Box>
                        </motion.div>

                        <Box width={"50%"} />
                        <Box textAlign={"justify"}>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <br />
                                <img width={"80%"} src={illution_4} alt="" /><br /><br />

                            </motion.div>
                            <label style={{ fontSize: 'xx-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("CREDIT_BAIL.texte.partie1")}</label>
                            <br /><br />
                            <label className='textgray' htmlFor="">
                                {t("CREDIT_BAIL.texte.partie2")}
                            </label>

                        </Box>
                    </Box>
                    <br />
                    <img width={"80%"} src={illution_4} alt="" /><br /><br />
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.8 }}
                        variants={scroll}
                        className='produitsEtServices'
                    >

                        <label className='textgray' htmlFor="" style={{ fontWeight: "bold", textAlign: "justify" }}>
                            {t("CREDIT_BAIL.texte.partie3")}
                        </label><br /><br />
                        <label className='textgray' htmlFor="" style={{ textAlign: "justify" }}>
                            {t("CREDIT_BAIL.texte.partie4")}
                        </label>
                    </motion.div>
                    <img width={"50%"} src={illution_4} alt="" />
                </Container>
                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseDroite} alt="" />
            </Box>
            <br /><br />

            <br /><br /><br />



            <Footer />
        </div>
    )
}
export default CreditBail