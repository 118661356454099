import Footer from '../Components/Footer'
import { useTranslation } from 'react-i18next'
import Assistante from "../Fichiers/Assistante.jpeg";
import { Box, Button, CircularProgress, Container, IconButton, TextField } from '@mui/material';
import { Variants, motion } from "framer-motion"
import DrawerAppBar from '../Components/DrawerAppBar';
import Menus from '../Components/Menus';
import TitrePage from '../Components/TitrePage';
import { Typography } from '@mui/material';
import { ArrowLeft, ArrowRight, CardTravel, Facebook, Instagram, LinkedIn, LocationOn, Mail, Money, PhoneAndroid } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import UploadFile from '../Components/UploadFile';
import EllipseGauche from "../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../Fichiers/Ellipse 201.svg"
import illution_4 from "../Fichiers/illution_4.svg";
import nousRejoindre from "../Fichiers/nousRejoindre.jpg";
import APropos from "../Database/APropos.json";
import { Carousel, Timeline } from 'antd';
import NosAgences from '../Components/NosAgences';
import Conf from "../Fichiers/12291123_Wavy_Tech-24_Single-01.svg";

interface Props { }

function PolitiqueDeConfidentialite(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [load, setLoad] = useState(false);


    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };


    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie1")} lien='Accueil / Politique de confidentialite' />
            <br /><br /><br /><br /><br /><br /><br /><br /><br />

            <br /><br />

            <Box display={"flex"}>
                <Box  display={{ xs: 'none', sm: 'contents' }}>
                    <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseGauche} alt="" />
                </Box>

                <Container style={{ backgroundColor: "rgba(255, 255, 255, .8)", borderRadius: "10px", boxShadow: "0px 0px 10px rgba(0, 0, 0, .3)", borderTop: "5px #0D6638 solid" }} maxWidth='lg'>
                    <center><img style={{position: "fixed", left: "3%", zIndex: "-1", opacity: "100%"}} width={300} src={Conf} alt="" /></center>
                    <br />
                    <label className='textgray' style={{ fontSize: 'large', fontWeight: "bold", textAlign: "right" }} htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie2")}</label>

                    <br /><br />
                    <hr />

                    <Box textAlign={"justify"}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie3").toUpperCase()}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie4")}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie5")}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie6")}</label>
                        </motion.div>


                    </Box>
                    <br />
                    <Box textAlign={"justify"}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie7").toUpperCase()}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie8")}</label>
                        </motion.div>

                    </Box>
                    <br />
                    <Box textAlign={"justify"}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie9").toUpperCase()}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie10")}</label>
                        </motion.div>

                    </Box>
                    <br />
                    <Box textAlign={"justify"}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie11").toUpperCase()}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie12")}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie13")}</label>
                        </motion.div>

                    </Box>
                    <br />
                    <Box textAlign={"justify"}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie14").toUpperCase()}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie15")}</label>
                        </motion.div>

                    </Box>
                    <br />
                    <Box textAlign={"justify"}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie16").toUpperCase()}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie17")}</label>
                        </motion.div>

                    </Box>
                    <br />
                    <Box textAlign={"justify"}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie18").toUpperCase()}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie19")}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie20")}</label>
                        </motion.div>

                    </Box>
                    <br />
                    <Box textAlign={"justify"}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie21").toUpperCase()}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie22")}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie23")}</label>
                        </motion.div>

                    </Box>
                    <br />
                    <Box textAlign={"justify"}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie24").toUpperCase()}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie25")}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie26")}</label>
                        </motion.div>

                    </Box>
                    <br />
                    <Box textAlign={"justify"}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie27").toUpperCase()}</label>
                            <br /><br />
                            <label htmlFor="">{t("POLITIQUE_DE_CONFIDENTIALITE.texte.partie28")}</label>
                        </motion.div>

                    </Box>


                    <img width={"50%"} src={illution_4} alt="" />
                    <br /><br />
                </Container>
                <Box display={{ xs: 'none', sm: 'contents' }}>
                    <img style={{ width: "5%", marginRight: "2%" }} draggable={false} src={EllipseDroite} alt="" />
                </Box>
            </Box>

            <br /><br /><br />



            <Footer />
        </div>
    )
}
export default PolitiqueDeConfidentialite