import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { useTranslation } from 'react-i18next'
import SousPub from '../Components/SousPub';
import illution_1 from "../Fichiers/illution_1.svg";
import illution_2 from "../Fichiers/illution_2.svg";
import illution_4 from "../Fichiers/illution_4.svg";
import { Box, Container } from '@mui/material';
import NosAgences from '../Components/NosAgences';
import { Variants, motion, useMotionValue, useTransform } from "framer-motion"
import NosProduitsEtServices from '../Components/NosProduitsEtServices';
import Assistant from '../Components/Assistant';
import NosActualitesEtEvenements from '../Components/NosActualitesEtEvenements';
import NosStatistique from '../Components/NosStatistique';
import NosPartenaire from '../Components/NosPartenaire';
import DrawerAppBar from '../Components/DrawerAppBar';
import Menus from '../Components/Menus';
import TitrePage from '../Components/TitrePage';
import NosOffres from '../Components/NosOffres';
import { Typography } from 'antd';
import NousRejoindre from '../Components/NousRejoindre';
import { useNavigate } from 'react-router-dom';

interface Props { }

function EmploieEtCarriere(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()


    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };

    function postules() {
        navigate("/postule")
    }


    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={'EMPLOI ET CARRIERE CHEZ MUPECI'} lien='Accueil / Emplois & carrière' />
            <br /><br /><br /><br /><br /><br /><br /><br /><br />
            <Box display={{ xs: 'block', sm: 'none' }}>
                <br /><br />
            </Box>

            <Box>
                <Container maxWidth='lg'>
                    <NousRejoindre />
                </Container>
            </Box>
            <br /><br />


            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            >
                <Container maxWidth='lg'>
                    <Box className='boxCS'>
                        <Typography>{t("SOUS_TITRE.CandidatureSpontanee")}</Typography>
                        <br />
                        <motion.button
                            onClick={postules}
                            whileHover={{
                                scale: 1.2,
                                transition: { duration: 1 },
                            }}
                            whileTap={{ scale: 0.9 }}
                            className='btn'>Condidature spontanée
                        </motion.button>
                    </Box>
                </Container>

            </motion.div>

            <br /><br />

            <Container maxWidth='lg'>
                <NosOffres />
            </Container>
            <br /><br /><br />

            <Footer />
        </div>
    )
}
export default EmploieEtCarriere