import Footer from '../Components/Footer'
import { useTranslation } from 'react-i18next'
import Assistante from "../Fichiers/Assistante.jpeg";
import { Avatar, Box, Button, CircularProgress, Container, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField } from '@mui/material';
import { Variants, motion } from "framer-motion"
import DrawerAppBar from '../Components/DrawerAppBar';
import Menus from '../Components/Menus';
import TitrePage from '../Components/TitrePage';
import { Typography } from '@mui/material';
import { ArrowLeft, ArrowRight, Facebook, Home, Instagram, LinkedIn, LocationOn, Mail, PhoneAndroid, TextDecrease } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import UploadFile from '../Components/UploadFile';
import EllipseGauche from "../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../Fichiers/Ellipse 201.svg"
import illution_4 from "../Fichiers/illution_4.svg";
import nousRejoindre from "../Fichiers/nousRejoindre.jpg";
import APropos from "../Database/APropos.json";
import { Carousel } from 'antd';
import StepperAgence from '../Components/StepperAgence';
import StepperTempsFort from '../Components/StepperTempsFort';

interface Props { }

function Gouvernance(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [load, setLoad] = useState(false);


    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };


    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={'GOUVERNANCE'} lien='Accueil / A Propos / Gouvernance' />
            <br /><br /><br /><br /><br /><br /><br /><br />



            <Box display={"flex"} lineHeight={1.6} textAlign={"justify"}>
                <img style={{ width: "5%", marginRight: "2%" }} draggable={false} src={EllipseGauche} alt="" />

                <Container maxWidth='lg'>

                    <Box display={{ xs: 'block', sm: 'flex' }} >

                        <Box textAlign={"justify"}>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <br />
                                <img width={"80%"} src={illution_4} alt="" /><br />

                            </motion.div>
                            <label style={{ fontSize: 'xx-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{"NOTRE " + t("GOUVERNANCE.texte.partie1")}</label>
                            <br /><br />
                            <label className='textgray' style={{ fontSize: 'large', fontWeight: "bold" }} htmlFor="">
                                {t("GOUVERNANCE.texte.partie2")}
                            </label>
                            <br /><br />
                            <label className='textgray' style={{ width: "30%" }} htmlFor="">
                                {t("GOUVERNANCE.texte.partie3").split("ON AVANCE ENSEMBLE")[0]}
                                <b style={{ color: "orange" }}>{t("MOT_DG.texte.partie6")}</b>
                            </label>
                            <br /><br />

                        </Box>
                        <Box width={120} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <Box>
                                <motion.div
                                    whileHover={{ scale: 1, rotate: 1 }}
                                    whileTap={{
                                        scale: 1,
                                        rotate: -1,
                                    }}
                                >
                                    <center><br /><br />
                                        <Box>
                                            <div><img style={{ borderRadius: "10%" }} width={300} height={300} src={"https://leconomie.info/wp-content/uploads/2023/07/mupeci-1.jpg"} alt="" /></div>
                                        </Box>
                                    </center>
                                </motion.div>
                            </Box>
                        </motion.div>
                    </Box>
                    <br />
                    <img width={"80%"} src={illution_4} alt="" /><br /><br />
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.8 }}
                        variants={scroll}
                        className='produitsEtServices'
                    >
                        <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold" }} htmlFor="">
                            {t("GOUVERNANCE.texte.partie4")}
                        </label>
                    </motion.div>
                    <br />
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.8 }}
                        variants={scroll}
                        className='produitsEtServices'
                    >
                        <label className='textgray' style={{ fontSize: 'xx-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">
                            {t("GOUVERNANCE.texte.partie5")}
                        </label>
                    </motion.div>
                    <br /><br />


                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box border={2} width={300} height={300} borderRadius={2} borderColor={"#0D6638"} padding={1}>
                            <Carousel style={{ width: "100%" }} dots={true} autoplay >
                                <div  ><img width={300} height={300} src={"https://lefinancierdafrique.com/wp-content/uploads/2023/08/Zephyrin-Blaise-Tchoupou-DG-Mupeci-1024x738.jpg"} alt="" /></div>
                                <div  ><img width={300} height={300} src={"https://lefinancierdafrique.com/wp-content/uploads/2023/07/Zephyrin-Blaise-Tchoupou-Directeur-general-de-la-Mupeci-1-882x1024.jpg"} alt="" /></div>
                            </Carousel>
                        </Box>
                        <Box width={100} />
                        <Box width={"100%"}>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' htmlFor="">
                                    {t("GOUVERNANCE.texte.partie6")}
                                </label>
                            </motion.div>
                            <br /><br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                    {t("GOUVERNANCE.texte.partie7")}
                                </label>
                            </motion.div>

                        </Box>
                    </Box>
                    <br />
                    <Box>
                        <img width={"50%"} src={illution_4} alt="" />
                        <Box width={20} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >

                            <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C", width: "100%" }} htmlFor="">
                                {t("GOUVERNANCE.texte.partie8")}
                            </label>
                        </motion.div>
                    </Box>
                    <br />
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.8 }}
                        variants={scroll}
                        className='produitsEtServices'
                    >
                        <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                            {t("GOUVERNANCE.texte.partie9")}
                        </label>
                    </motion.div>
                    <br /><br />
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.8 }}
                        variants={scroll}
                        className='produitsEtServices'
                    >
                        <label className='textgray' style={{ width: "30%", textAlign: "justify", fontSize: "large", fontWeight: "bold" }} htmlFor="">
                            {t("GOUVERNANCE.texte.partie10")}
                        </label>
                    </motion.div>
                    <br />

                    <List>
                        {(t("GOUVERNANCE.texte.partie11").split("|")).map((t) => (
                            <>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt="Remy Sharp"><Home /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={t}
                                        secondary={
                                            <React.Fragment>
                                                {"Tenu à la direction générale"}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </>
                        ))}
                    </List>
                    <label className='textgray' htmlFor="">
                        {t("GOUVERNANCE.texte.partie12")}
                    </label>

                    <br />

                    <br />

                    <Box>
                        <img width={"50%"} src={illution_4} alt="" />
                        <Box width={20} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C", width: "100%" }} htmlFor="">
                                {t("GOUVERNANCE.texte.partie13")}
                            </label>
                        </motion.div>
                    </Box>
                    <br />


                    <Box width={"100%"}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' htmlFor="">
                                {t("GOUVERNANCE.texte.partie14")}
                            </label>
                        </motion.div>
                        <br />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                {t("GOUVERNANCE.texte.partie15")}
                            </label>
                        </motion.div>

                    </Box>



                    <br />

                    <Box >
                        <img width={"50%"} src={illution_4} alt="" />
                        <Box width={20} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C", width: "100%" }} htmlFor="">
                                {t("GOUVERNANCE.texte.partie16")}
                            </label>
                        </motion.div>
                    </Box>
                    <br />


                    <Box width={"100%"}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' htmlFor="">
                                {t("GOUVERNANCE.texte.partie17")}
                            </label>
                        </motion.div>
                        <br />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                {t("GOUVERNANCE.texte.partie18")}
                            </label>
                        </motion.div>
                        <br />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                {t("GOUVERNANCE.texte.partie19")}
                            </label>
                        </motion.div>
                        <br />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                {t("GOUVERNANCE.texte.partie20")}
                            </label>
                        </motion.div>
                        <br />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <label className='textgray' style={{ width: "30%", textAlign: "justify" }} htmlFor="">
                                {t("GOUVERNANCE.texte.partie21")}
                            </label>
                        </motion.div>

                    </Box>

                    <img width={"50%"} src={illution_4} alt="" />
                    <br /><br />
                </Container>
                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseDroite} alt="" />
            </Box>
            <br /><br />


            <br /><br /><br />



            <Footer />
        </div>
    )
}
export default Gouvernance