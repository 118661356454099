import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import Agences from "../Database/Agences.json";
import Agence from '../Models/Agence';
import { HomeRounded, LocationOn, MarkEmailRead } from '@mui/icons-material';
import CoordonneMap from '../Models/CoordonneMap';

export default function Regions(props: { centralize: Function }) {

    const [region, setRegion] = React.useState<Array<string>>([])
    const [pageLoad, setPageLoad] = React.useState(true)


    function onlyUnique(value: any, index: any, array: any) {
        return array.indexOf(value) === index;
    }
    if (pageLoad) {
        setPageLoad(false)


        var reg: Array<string> = []
        Agences.map((a: Agence) => {
            reg.push(a.region)
        })
        setRegion(reg.filter(onlyUnique))
    }

    return (
        <Box overflow={"auto"} height={400} marginTop={4} display={"block"}>
            {
                region.map((r) => (
                    <Accordion style={{ padding: "0%", margin: "0%" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography fontWeight={"bold"} color={"green"}>
                                <LocationOn color='warning' />
                                {r}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {Agences.filter((a) => a.region == r).map((a: Agence) => (
                                    <>
                                        <ListItem onClick={() => { props.centralize(a.coordonnees) }} title='cliquez pour voir la localisation dans le map' className='lien' style={{ backgroundColor: "rgba(255, 164, 18, .03)", borderRadius: "5px" }}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <HomeRounded />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <Box>
                                                <ListItemText primary={a.numero + " - " + a.agence} secondary={a.emplacement} />
                                                <ListItemText secondary={"(+237) " + a.telephone} />
                                            </Box>
                                        </ListItem>
                                    </>
                                ))}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </Box>
    );
}