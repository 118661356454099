import { Box, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'
import { motion } from "framer-motion";
import ProduitServices from '../Models/ProduitServices';
import { useNavigate } from 'react-router-dom';
import { Empty } from 'antd';
import Files from "../Database/Files.json";

function ProduitOuService(props: { produitService: ProduitServices }) {
    const navigate = useNavigate()

    const handleClick = (event: React.MouseEvent<HTMLDivElement>, link: string) => {
        navigate(link)
        const anchor =
            (
                (event.target as HTMLDivElement).ownerDocument || document
            ).querySelector(link.includes("#") ? ("#" + link.split("#")[1]): "#back-to-top-anchor");

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }
    };

    return (
        <Box>
            <Card square sx={{ maxWidth: "90%" }} className='produitCard'>
                {
                    props.produitService.image ?
                        <CardMedia
                            sx={{ height: 100 }}
                            image={require("../Database/Files/ProduitsEtServices/"+props.produitService.image)}
                        />
                    :
                    <Empty />
                }

                <CardContent>
                    <Typography style={{ fontSize: "100%" }} gutterBottom variant="h5" component="div">
                        {props.produitService.titre}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.produitService.description}
                    </Typography>
                </CardContent>
                <CardActions style={{ backgroundColor: "#0D6638" }}>
                    <motion.button
                        whileHover={{
                            scale: 1.2,
                            transition: { duration: 1 },
                        }}
                        whileTap={{ scale: 0.9 }}
                        onClick={(e : any) => { handleClick(e, props.produitService.link) }}
                        className='btn2'>En savoir plus</motion.button>
                </CardActions>
            </Card>
        </Box>
    )
}

export default ProduitOuService
