import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { LinkedIn, Facebook, Mail, Home, Phone, Email } from '@mui/icons-material';
import { Container, Fab, Fade, Tooltip, useScrollTrigger } from '@mui/material';
import logo from "../Fichiers/Logo.png"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Langue from './Langue';
import { Link, useNavigate } from 'react-router-dom';
import SearchPage from './SearchPage';
import MenuMobile from './MenuMobile';
import { t } from 'i18next';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: React.ReactElement;
}

const drawerWidth = 240;
export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const navigate = useNavigate()

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  function ScrollTop(props: Props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      const anchor = (
        (event.target as HTMLDivElement).ownerDocument || document
      ).querySelector('#back-to-top-anchor');

      if (anchor) {
        anchor.scrollIntoView({
          block: 'center',
        });
      }
    };

    return (
      <Fade in={trigger}>
        <Tooltip title="Remonter en haut de la page">
          <Box
            onClick={handleClick}
            role="presentation"
            sx={{ position: 'fixed', bottom: 200, right: 20 }}
          >
            {children}
          </Box>
        </Tooltip>
      </Fade>
    );
  }
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <Toolbar id="back-to-top-anchor" />

      <Box zIndex={1}>
        <ScrollTop {...props}>
          <Fab color='warning' size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </Box>

      <AppBar position="fixed" variant='outlined' style={{ background: "#F1FFF8", border: "none", boxShadow: "0px 3px 20px rgba(0, 0, 0, .1)", zIndex: "2" }} component="nav">
        <Container maxWidth="lg">
          <Toolbar>
            <IconButton
              color="success"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box display={{ xs: 'block', sm: 'flex' }} gap={2}>
              <Link to={'/'}><img className='logo' width={"90%"} src={logo} alt="" /></Link>
              <Box display={"flex"}>
                <Box padding={1} gap={1} display={'flex'}>
                  <Box style={{ marginLeft: '0%' }} className='header-icon'><IconButton size='small' color='success'><Home /></IconButton></Box>
                  <Box width={150} display={{ xs: 'none', sm: 'block' }} >
                    <label className='primaryText'>Douala Cameroun</label><br />
                    <label className='seconderyText'>Feu Rouge Bessengue</label>
                  </Box>
                </Box>

                <Box padding={1} gap={1} display={'flex'}>
                  <Box style={{ marginLeft: '0%' }} className='header-icon'><IconButton size='small' color='success'><Phone /></IconButton></Box>
                  <Box width={150} display={{ xs: 'none', sm: 'block' }} >
                    <label className='primaryText'>Téléphone</label><br />
                    <label className='seconderyText'>(+237) 233 42 82 90</label>
                  </Box>
                </Box>

                <Box padding={1} gap={1} display={'flex'}>
                  <Box style={{ marginLeft: '0%' }} className='header-icon'><IconButton size='small' color='success'><Email /></IconButton></Box>
                  <Box width={150} display={{ xs: 'none', sm: 'block' }} >
                    <label className='primaryText'>Email</label><br />
                    <label className='seconderyText'>contact@mupeci.cm</label>
                  </Box>
                  <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <Langue />
                  </Box>
                </Box>
              </Box>
              <Box padding={1} sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Box gap={1} display={"flex"}>
                  <Link to="https://www.linkedin.com/company/mupeci-1" target="_blank"><IconButton size='small' style={{ border: "1px solid" }} color='info'><LinkedIn /></IconButton></Link>
                  <Link to="https://www.facebook.com/mupeci" target="_blank"><IconButton size='small' style={{ border: "1px solid" }} color='info'><Facebook /></IconButton></Link>
                  <Link to="mailto:contact@mupeci.cm" target="_blank"><IconButton size='small' style={{ border: "1px solid" }} color='error'><Mail /></IconButton></Link>
                  <hr />
                  <SearchPage />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }} className="right">
              <Langue />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <Link to={"/"}><img style={{padding: "10%"}} width={"80%"} src={logo} /></Link>
          <Divider />
          <MenuMobile />
          <Divider /><br />
          <center style={{width: "100%", fontSize: "small"}} className='textgray'>{t("copyright")}</center>
        </Drawer>
      </nav>
    </Box>
  );
}
