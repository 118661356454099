import Footer from '../Components/Footer'
import { useTranslation } from 'react-i18next'
import illution_4 from "../Fichiers/illution_4.svg";
import { Avatar, Box, Container, Divider, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { Variants, motion } from "framer-motion"
import DrawerAppBar from '../Components/DrawerAppBar';
import Menus from '../Components/Menus';
import TitrePage from '../Components/TitrePage';
import { useNavigate } from 'react-router-dom';
import EllipseGauche from "../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../Fichiers/Ellipse 201.svg"
import { TextFields } from '@mui/icons-material';

interface Props { }

function DevenirMembre(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()


    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };

    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={t("DevenirMembre.titre")} lien={'Accueil / A Propos / ' + t("DevenirMembre.titre")} />
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <Box display={{ xs: 'block', sm: 'none' }}>
                <br /><br />
            </Box>

            <Box display={"flex"} lineHeight={1.6} textAlign={"justify"}>
                <Box gap={5} display={{ sx: "none", sm: "contents" }}>
                    <img style={{ width: "5%" }} draggable={false} src={EllipseGauche} alt="" />
                </Box>
                <Container maxWidth='lg'>

                    <Box gap={5} display={{ sx: "block", sm: "flex" }}>
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <Box>
                                <motion.div
                                    whileHover={{ scale: 1, rotate: 1 }}
                                    whileTap={{
                                        scale: 1,
                                        rotate: -1,
                                    }}
                                >
                                    <img style={{ borderTopLeftRadius: "30%", borderTopRightRadius: "10%", borderBottomLeftRadius: "10%", borderBottomRightRadius: "30%" }} width={300} height={300} src={require("../Database/Files/Contactez nous/1 9466812.png")} alt="" />
                                </motion.div>
                            </Box>
                        </motion.div>
                        <Box >

                            <img width={"80%"} src={illution_4} alt="" /><br /><br />
                            <label style={{ fontSize: 'xx-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("DevenirMembre.texte.partie1").toUpperCase()}</label>
                            <br /><br />
                            <label className='textgray' style={{ width: "30%" }} htmlFor="">
                                {t("DevenirMembre.texte.partie2")}
                            </label>
                            <br /><br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label style={{ fontSize: 'xx-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("DevenirMembre.texte.partie3").toUpperCase()}</label>
                                <br /><br />
                                <label style={{ fontSize: 'large', fontWeight: "bold", color: "#FFA412" }} htmlFor="">{t("DevenirMembre.texte.partie4").toUpperCase()}</label>
                            </motion.div>
                            <br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <List style={{ borderLeft: "5px #FFA412 solid" }}>
                                    {(t("DevenirMembre.texte.partie5").split("|")).map((t) => (
                                        <>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp"><TextFields /></Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={t}
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </>
                                    ))}
                                </List>
                                <motion.button
                                    onClick={()=>{navigate("/Contact")}}
                                    whileHover={{
                                        scale: 1.2,
                                        transition: { duration: 1 },
                                    }}
                                    whileTap={{ scale: 0.9 }}
                                    className='btn'>Contacter
                                </motion.button>
                            </motion.div>
                            <br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label style={{ fontSize: 'large', fontWeight: "bold", color: "#FFA412" }} htmlFor="">{t("DevenirMembre.texte.partie6").toUpperCase()}</label>
                            </motion.div>
                            <br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <List style={{ borderLeft: "5px #FFA412 solid" }}>
                                    {(t("DevenirMembre.texte.partie7").split("|")).map((t) => (
                                        <>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp"><TextFields /></Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={t}
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </>
                                    ))}
                                </List>
                                <motion.button
                                    onClick={()=>{navigate("/Contact")}}
                                    whileHover={{
                                        scale: 1.2,
                                        transition: { duration: 1 },
                                    }}
                                    whileTap={{ scale: 0.9 }}
                                    className='btn'>Contacter
                                </motion.button>
                            </motion.div>

                            <br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label style={{ fontSize: 'large', fontWeight: "bold", color: "#FFA412" }} htmlFor="">{t("DevenirMembre.texte.partie8").toUpperCase()}</label>
                            </motion.div>
                            <br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <List style={{ borderLeft: "5px #FFA412 solid" }}>
                                    {(t("DevenirMembre.texte.partie9").split("|")).map((t) => (
                                        <>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp"><TextFields /></Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={t}
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </>
                                    ))}
                                </List>
                                <motion.button
                                    onClick={()=>{navigate("/Contact")}}
                                    whileHover={{
                                        scale: 1.2,
                                        transition: { duration: 1 },
                                    }}
                                    whileTap={{ scale: 0.9 }}
                                    className='btn'>Contacter
                                </motion.button>
                            </motion.div>


                            <br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label style={{ fontSize: 'large', fontWeight: "bold", color: "#FFA412" }} htmlFor="">{t("DevenirMembre.texte.partie10").toUpperCase()}</label>
                            </motion.div>
                            <br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <List style={{ borderLeft: "5px #FFA412 solid" }}>
                                    {(t("DevenirMembre.texte.partie11").split("|")).map((t) => (
                                        <>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp"><TextFields /></Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={t}
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </>
                                    ))}
                                </List>
                                <motion.button
                                    onClick={()=>{navigate("/Contact")}}
                                    whileHover={{
                                        scale: 1.2,
                                        transition: { duration: 1 },
                                    }}
                                    whileTap={{ scale: 0.9 }}
                                    className='btn'>Contacter
                                </motion.button>
                            </motion.div>





                            <br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label style={{ fontSize: 'large', fontWeight: "bold", color: "#FFA412" }} htmlFor="">{t("DevenirMembre.texte.partie12").toUpperCase()}</label>
                            </motion.div>
                            <br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <List style={{ borderLeft: "5px #FFA412 solid" }}>
                                    {(t("DevenirMembre.texte.partie13").split("|")).map((t) => (
                                        <>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp"><TextFields /></Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={t}
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </>
                                    ))}
                                </List>
                                <motion.button
                                    onClick={()=>{navigate("/Contact")}}
                                    whileHover={{
                                        scale: 1.2,
                                        transition: { duration: 1 },
                                    }}
                                    whileTap={{ scale: 0.9 }}
                                    className='btn'>Contacter
                                </motion.button>
                            </motion.div>










                            <br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label style={{ fontSize: 'large', fontWeight: "bold", color: "#FFA412" }} htmlFor="">{t("DevenirMembre.texte.partie14").toUpperCase()}</label>
                            </motion.div>
                            <br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <List style={{ borderLeft: "5px #FFA412 solid" }}>
                                    {(t("DevenirMembre.texte.partie15").split("|")).map((t) => (
                                        <>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp"><TextFields /></Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={t}
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </>
                                    ))}
                                </List>
                                <motion.button
                                    onClick={()=>{navigate("/Contact")}}
                                    whileHover={{
                                        scale: 1.2,
                                        transition: { duration: 1 },
                                    }}
                                    whileTap={{ scale: 0.9 }}
                                    className='btn'>Contacter
                                </motion.button>
                            </motion.div>




                            <br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label style={{ fontSize: 'large', fontWeight: "bold", color: "#FFA412" }} htmlFor="">{t("DevenirMembre.texte.partie16").toUpperCase()}</label>
                            </motion.div>
                            <br />
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <List style={{ borderLeft: "5px #FFA412 solid" }}>
                                    {(t("DevenirMembre.texte.partie17").split("|")).map((t) => (
                                        <>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp"><TextFields /></Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={t}
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </>
                                    ))}
                                </List>
                                <motion.button
                                    onClick={()=>{navigate("/Contact")}}
                                    whileHover={{
                                        scale: 1.2,
                                        transition: { duration: 1 },
                                    }}
                                    whileTap={{ scale: 0.9 }}
                                    className='btn'>Contacter
                                </motion.button>
                            </motion.div>

                        </Box>
                    </Box>

                </Container>
                <Box gap={5} display={{ sx: "none", sm: "contents" }}>
                    <img style={{ width: "5%" }} draggable={false} src={EllipseDroite} alt="" />
                </Box>
            </Box>
            <br /><br />
            <br /><br /><br />

            <Footer />
        </div>
    )
}
export default DevenirMembre