import Footer from '../Components/Footer'
import { useTranslation } from 'react-i18next'
import Assistante from "../Fichiers/Assistante.jpeg";
import { Box, Button, CircularProgress, Container, IconButton, TextField } from '@mui/material';
import { Variants, motion } from "framer-motion"
import DrawerAppBar from '../Components/DrawerAppBar';
import Menus from '../Components/Menus';
import TitrePage from '../Components/TitrePage';
import { Typography } from '@mui/material';
import { Facebook, Instagram, LinkedIn, LocationOn, Mail, PhoneAndroid } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import UploadFile from '../Components/UploadFile';
import EllipseGauche from "../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../Fichiers/Ellipse 201.svg"
import env from "react-dotenv";

interface Props { }

function Reclamations(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [load, setLoad] = useState(false);


    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };

    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={'RECLAMATIONS'} lien='Accueil / Reclamations' />
            <br /><br /><br /><br /><br /><br /><br /><br />
            <br />
            <br />

            {/* <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            >
                <Container maxWidth='md'>
                    <Box display={"flex"} >
                        <center>
                            <img className='move' style={{ borderRadius: "100%" }} width={"20%"} src={Assistante} alt="" />
                        </center>
                    </Box>
                </Container>
            </motion.div>
            <br /><br /> */}
            <Box display={"flex"}>
                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseGauche} alt="" />

                <embed style={{borderRadius: "2%"}} height={1000} width={"100%"} src={env.GOOGLE_FORM_RECLAMATION_URL} />

                <img style={{ width: "5%", marginRight: "2%" }} draggable={false} src={EllipseDroite} alt="" />
            </Box>
            <br /><br /><br />
            <Footer />
        </div>
    )
}
export default Reclamations