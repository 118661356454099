import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import Nav from './Nav';
import env from 'react-dotenv';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children?: React.ReactElement;
}

export default function Menus(props: Props) {

    return (
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <AppBar position="relative" variant='outlined' style={{ height: "90px", background: "rgba(0, 0, 0, 0)", border: "none", top: "8%", zIndex: 1}} component="nav">
                <Container className="nav" maxWidth="xl">
                    <center>
                        <Nav />
                        <Link className='nav-btn-online' style={{ margin: "10px" }} to={env.MUPECI_ONLINE_LINK} target="_blank">{"MUPECI ONLINE"}</Link>
                    </center>
                </Container>
            </AppBar>
        </Box>
    );
}
