import Footer from '../Components/Footer'
import { useTranslation } from 'react-i18next'
import { Box, Container } from '@mui/material';
import { Variants, motion } from "framer-motion"
import DrawerAppBar from '../Components/DrawerAppBar';
import Menus from '../Components/Menus';
import TitrePage from '../Components/TitrePage';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import EllipseGauche from "../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../Fichiers/Ellipse 201.svg"
import illution_4 from "../Fichiers/illution_4.svg";
import { Carousel } from 'antd';
import Files from "../Database/Files.json";

interface Props { }

function MotDG(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [load, setLoad] = useState(false);


    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };


    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={'MOT DU DG'} lien='Accueil / A Propos / Mot du DG' />
            <br /><br /><br /><br /><br /><br /><br /><br />
            


            <Box display={"flex"}>
                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseGauche} alt="" />

                <Container maxWidth='lg'>

                    <Box display={{ xs: 'block', sm: 'flex' }} >
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <Box>
                                <motion.div
                                    whileHover={{ scale: 1, rotate: 1 }}
                                    whileTap={{
                                        scale: 1,
                                        rotate: -1,
                                    }}
                                >
                                    <center><br /><br />
                                        <Box overflow={"hidden"} border={2} width={300} height={300} borderRadius={2} borderColor={"#0D6638"}>
                                            <Carousel style={{width: "100%"}} dots={true} autoplay >
                                                {Files.A_PROPOS.MotDuDG.map((image)=>(
                                                <div><img width={"100%"} height={"100%"} src={require("../Database/Files/APropos/MotDuDG/"+image)} alt=""/></div>
                                                ))}
                                            </Carousel>
                                        </Box>
                                        <br /><br />
                                        <label style={{ fontSize: 'large', fontWeight: "bold", color: "#40805C" }} htmlFor="">
                                            {t("MOT_DG.texte.partie7")}
                                        </label>
                                        <br />
                                        <label className='textgray' style={{ width: "30%" }} htmlFor="">
                                            {t("MOT_DG.texte.partie8")}
                                        </label>
                                    </center>
                                </motion.div>
                            </Box>
                        </motion.div>
                        <Box margin={"2%"} textAlign={"justify"}>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <img width={"80%"} src={illution_4} alt="" /><br /><br />

                            </motion.div>
                            <label style={{ fontSize: 'xx-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("MOT_DG.texte.partie1")}</label>
                            <br /><br />
                            <label className='textgray' style={{ fontSize: 'large', fontWeight: "bold" }} htmlFor="">
                                {t("MOT_DG.texte.partie2")}
                            </label>
                            <br /><br />
                            <label className='textgray' style={{ width: "30%" }} htmlFor="">
                                {t("MOT_DG.texte.partie3")}
                            </label>
                            <br /><br />
                            <label className='textgray' style={{ width: "30%" }} htmlFor="">
                                {t("MOT_DG.texte.partie4")}
                            </label>
                            <br /><br />
                            <label className='textgray' style={{ width: "30%" }} htmlFor="">
                                {t("MOT_DG.texte.partie5")}
                            </label>
                            <br /><br />
                            <label className='textgray' style={{ width: "30%", color: "#FFA412", fontWeight: "bold" }} htmlFor="">
                                {t("MOT_DG.texte.partie6") + ".."}
                            </label>
                            <br /><br /><br />
                        </Box>
                    </Box>
                </Container>
                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseDroite} alt="" />
            </Box>

            <br /><br />

            <br /><br /><br />



            <Footer />
        </div>
    )
}
export default MotDG