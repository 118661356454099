import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'
import imgProduit from "../Fichiers/Epargne Ordinaire 444.png";
import { motion } from "framer-motion";
import OffreM from '../Models/Offre';
import { useNavigate } from 'react-router-dom';

function Offre(props: { offre: OffreM }) {
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLDivElement>, link: string) => {
        const anchor =
            (
                (event.target as HTMLDivElement).ownerDocument || document
            ).querySelector(link.includes("#") ? ("#" + link.split("#")[1]) : "#back-to-top-anchor");

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }
        navigate(link)
    };

    return (
        <Box>
            <Card square sx={{ maxWidth: "90%" }} className='produitCard round'>
                <CardMedia
                    sx={{ height: 100 }}
                    image={props.offre.image}
                />
                <CardContent>
                    <Typography style={{fontSize: "100%"}} gutterBottom variant="h5" component="div">
                        {props.offre.Titre}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        du {props.offre.dateDebut.split("T")[0]} au {props.offre.dateFin.split("T")[0]}
                    </Typography>
                </CardContent>
                <CardActions style={{backgroundColor: "#0D6638"}}>
                <motion.button
                    whileHover={{
                        scale: 1.2,
                        transition: { duration: 1 },
                    }}
                    whileTap={{ scale: 0.9 }}
                    onClick={(e : any)=>{handleClick(e, "/CandidatureSpontanee?offre="+props.offre.CodeOffres)}}
                    className='btn2'>Postuller</motion.button>
                </CardActions>
            </Card>
        </Box>
    )
}

export default Offre
