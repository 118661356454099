import Footer from '../Components/Footer'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Container, Divider, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { Variants, motion } from "framer-motion"
import DrawerAppBar from '../Components/DrawerAppBar';
import Menus from '../Components/Menus';
import TitrePage from '../Components/TitrePage';
import { TextFields } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import EllipseGauche from "../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../Fichiers/Ellipse 201.svg"
import illution_4 from "../Fichiers/illution_4.svg";
import { Link } from 'react-router-dom';

interface Props { }

function EpargneIslamique(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [load, setLoad] = useState(false);


    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };


    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={t("EPARGNE_ISLAMIQUE.texte.partie1")} lien='Accueil / Epargne / Epargne Islamique' />
            <br /><br /><br /><br /><br /><br /><br /><br />



            <Box display={"flex"} lineHeight={1.6} textAlign={"justify"}>
                <img style={{ width: "5%", marginRight: "2%" }} draggable={false} src={EllipseGauche} alt="" />

                <Container maxWidth='lg'>
                    <Box display={{ xs: 'block', sm: 'flex' }} >

                        <Box textAlign={"justify"}>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <br />
                                <img width={"80%"} src={illution_4} alt="" /><br /><br />

                            </motion.div>
                            <label style={{ fontSize: 'large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("EPARGNE_ISLAMIQUE.texte.partie1")}</label>
                            <br /><br />
                            <label className='textgray' htmlFor="">
                                {t("EPARGNE_ISLAMIQUE.texte.partie2")}
                            </label>

                        </Box>
                        <Box width={"50%"} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >
                            <Box>
                                <motion.div
                                    whileHover={{ scale: 1, rotate: 1 }}
                                    whileTap={{
                                        scale: 1,
                                        rotate: -1,
                                    }}
                                >
                                    <center><br /><br />
                                        <Box>
                                            <div><img style={{ borderRadius: "10%" }} width={250} src={require("../Database/Files/ProduitsEtServices/Epargnes/gettyimages-1772757696-2048x2048 1.png")} alt="" /></div>
                                        </Box>
                                    </center>
                                </motion.div>
                            </Box>
                        </motion.div>
                    </Box>
                    <img width={"50%"} src={illution_4} alt="" />
                    <br /><br />
                </Container>
                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseDroite} alt="" />
            </Box>
            <br /><br />


            <br /><br /><br />

            <Footer />
        </div>
    )
}
export default EpargneIslamique