import { Box, Container } from '@mui/material'
import { motion } from 'framer-motion'

import React from 'react'

interface Titre {
    titre: string,
    lien: string,
}

function TitrePage(props: Titre) {

    return (

        <Box className={"titrePage"}>
            <motion.div
            >
                <h2 className='texte_titre' >
                    {props.titre}
                    <hr style={{opacity: "50%"}} />
                    <center style={{fontSize: '100%', color: "rgba(255, 255, 255, .5)"}}>{props.lien}</center>
                </h2>
            </motion.div>
        </Box>
    )
}

export default TitrePage
