import Footer from '../Components/Footer'
import { useTranslation } from 'react-i18next'
import { Box, Button, CircularProgress, Container, IconButton, TextField } from '@mui/material';
import { Variants, motion } from "framer-motion"
import DrawerAppBar from '../Components/DrawerAppBar';
import Menus from '../Components/Menus';
import TitrePage from '../Components/TitrePage';
import { Typography } from '@mui/material';
import { Add, CardTravel, LocationOn, Money, Timeline } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import UploadFile from '../Components/UploadFile';
import EllipseGauche from "../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../Fichiers/Ellipse 201.svg"
import Offre from '../Models/Offre';
import Data from '../Data/.json'
import env from 'react-dotenv';
import axios from 'axios';
import Swal from 'sweetalert2';
import { message, Spin, UploadProps } from 'antd';
import Dragger from 'antd/es/upload/Dragger';


function Postule(props: {}) {
    const { } = props
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const [load, setLoad] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(true);
    const [loading, setLoading] = useState(false);
    const [Offre, setOffre] = useState<Offre | null>(null);

    const [filebase64, setFileBase64] = useState<string>("no file")

    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };

    if (pageLoaded) {
        setPageLoaded(false)
        try {
            const urlParams = new URLSearchParams(window.location.search).get("offre");
            if (urlParams != null) {
                LoadData(urlParams)
            }
        } catch (error) {

        }
    }

    function LoadData(urlParams: string) {
        try {
            setLoading(true)
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: env.API_BASE_URL + Data.API_LINKs.ReadOffres,
                headers: {
                    'CodeOffre': urlParams,
                    'Content-Type': 'application/json, application/json',
                    'Authorization': 'Bearer ' + env.API_TOKEN,
                }
            };

            axios.request(config)
                .then((response) => {
                    if (response.data.StatutCode == 200) {
                        setOffre(response.data.Result.Table1[0] as Offre)
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                });

        } catch (error) {
        }
    }

    function postuleSubmit(data: any) {
        if (filebase64 == "no file") {
            Swal.fire({
                icon: "info",
                title: "Veillez renseigner votre CV!",
                text: "Sélectionnez votre CV au format PDF."
            })
        } else {
            setLoad(true)
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: env.API_BASE_URL + Data.API_LINKs.Candidature,
                headers: {
                    'Content-Type': 'application/json, application/json',
                    'Authorization': 'Bearer ' + env.API_TOKEN,
                },
                data: {
                    "idCandidate": 1,
                    "datePostule": "10/10/2000",
                    "domaine": data.domaine,
                    "codeOffres": Offre?.CodeOffres+"",
                    "nomCandidate": data.nomCandidate,
                    "prenomCandidate": data.prenomCandidate,
                    "profilCandidate": data.profilCandidate,
                    "motivLetter": data.motivLetter,
                    "cvCandidate": filebase64
                }
            };
            axios.request(config)
                .then((response) => {
                    Swal.fire({
                        title: (response.data.StatutCode == 200) ? 'Candidature envoyé avec succès.' : "Erreur produit lors de l'envoie de la candidature.",
                        icon: (response.data.StatutCode == 200) ? 'success' : "info"
                    })
                    setLoad(false)
                    if (response.data.StatutCode == 200) {
                        reset()
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        title: error,
                        icon: 'error'
                    })
                    setLoad(false)
                });
         }
    }

    function convertFile(e: any) {
        var file = e.target.files[0]

        // Encode the file using the FileReader API
        const reader = new FileReader();
        reader.onloadend = () => {
            // Use a regex to remove data url part
            const base64String = (reader.result as any)
                .replace('data:', '')
                .replace(/^.+,/, '');

            // console.log(base64String);
            setFileBase64(base64String)
            // Logs wL2dvYWwgbW9yZ...
        };
        reader.readAsDataURL(file);
    }

    const prop = {
        multiple: false,
        // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        // onChange(info : any) {
        //     convertFile(info.file)
        //     // const { status } = info.file;
        //     // if (status !== 'uploading') {
        //     //     console.log(info.file, info.fileList);
        //     // }
        //     // if (status === 'done') {
        //     //     message.success(`${info.file.name} file uploaded successfully.`);
        //     // } else if (status === 'error') {
        //     //     message.error(`${info.file.name} file upload failed.`);
        //     // }
        // },
    };

    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={'EMPLOI ET CARRIERE CHEZ MUPECI'} lien='Accueil / Emplois & carrière / Offreule' />
            <br /><br /><br /><br /><br /><br /><br /><br />
            <br />
            <br />
            {
                loading ?
                    <center><CircularProgress color='warning' />
                        <br /><br /><br /></center>
                    :
                    <>
                        <Container maxWidth='lg'>
                            <Box display={"flex"}>
                                {Offre?.image != null ? <Box><img width={100} style={{ borderRadius: "10px" }} src={Offre?.image} /></Box> : ""}
                                <Box>
                                    {Offre != null ?
                                        <motion.div
                                            initial="offscreen"
                                            whileInView="onscreen"
                                            viewport={{ once: true, amount: 0.8 }}
                                            variants={scroll}
                                            className='produitsEtServices'
                                        >
                                            <Container maxWidth='lg'>
                                                <Box display={"flex"} className='boxCS'>
                                                    <Box>
                                                        <Typography fontWeight={"bold"} variant='h5'>Offre : <mark style={{ backgroundColor: "rgba(255, 164, 18, .6)", padding: "3px", borderRadius: "5px" }}>{Offre?.Titre}</mark></Typography>
                                                        <Typography>{Offre?.description}</Typography>
                                                    </Box>
                                                </Box>
                                            </Container>

                                        </motion.div>
                                        :
                                        ""}
                                </Box>
                            </Box>
                        </Container>
                        <br /><br />

                        <Box display={"flex"}>
                            <Box display={{ xs: 'none', sm: 'contents' }}>
                                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseGauche} alt="" />
                            </Box>

                            <Container maxWidth='lg'>
                                <Box display={Offre != null ? { xs: 'block', sm: 'flex' } : 'block'}>
                                    {
                                        Offre != null ?
                                            <Box className='resume'>
                                                <Box>
                                                    <Typography padding={"5%"} fontWeight={"bold"} variant='h5'>Information suplémentaire.</Typography>
                                                    <Box padding={"5%"} gap={1} display={'flex'}>
                                                        <Box className='header-icon'><IconButton size='small' color='success'><LocationOn /></IconButton></Box>
                                                        <Box >
                                                            <label className='primaryText'>Localisation</label><br />
                                                            <label className='seconderyText'>{Offre?.localisation}</label>
                                                        </Box>
                                                    </Box>

                                                    <Box padding={"5%"} gap={1} display={'flex'}>
                                                        <Box className='header-icon'><IconButton size='small' color='success'><CardTravel /></IconButton></Box>
                                                        <Box >
                                                            <label className='primaryText'>Domaines</label><br />
                                                            <label className='seconderyText'>{Offre?.domaine}</label>
                                                        </Box>
                                                    </Box>

                                                    <Box padding={"5%"} gap={1} display={'flex'}>
                                                        <Box className='header-icon'><IconButton size='small' color='success'><CardTravel /></IconButton></Box>
                                                        <Box >
                                                            <label className='primaryText'>Secteurs</label><br />
                                                            <label className='seconderyText'>{Offre?.secteur}</label>
                                                        </Box>
                                                    </Box>

                                                    <Box padding={"5%"} gap={1} display={'flex'}>
                                                        <Box className='header-icon'><IconButton size='small' color='success'><Money /></IconButton></Box>
                                                        <Box >
                                                            <label className='primaryText'>Salaire</label><br />
                                                            <label className='seconderyText'>{Offre?.salaire}</label>
                                                        </Box>
                                                    </Box>
                                                    <Box padding={"5%"} gap={1} display={'flex'}>
                                                        <Box className='header-icon'><IconButton size='small' color='success'><Timeline /></IconButton></Box>
                                                        <Box >
                                                            <label className='primaryText'>Mode de travaille</label><br />
                                                            <label className='seconderyText'>{Offre?.mode_travaille}</label>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                            </Box>
                                            :
                                            ""
                                    }
                                    <hr style={{ visibility: 'hidden' }} />
                                    <Box width={Offre != null ? { xs: '100%', sm: '65%' } : "100%"} className='OffreuleForm'>

                                        <Spin spinning={load}>
                                            <form onSubmit={handleSubmit(postuleSubmit)}>
                                                <Typography fontWeight={"bold"} variant='h5'>{"Formulaire de soumission.".toUpperCase()}</Typography>
                                                <label className='textgray'>Veillez remblir tous ces champs pour emmettre votre candidature</label><br /><br />
                                                <Box>
                                                    <Box >
                                                        <Box display={"flex"} gap={2}>
                                                            <TextField
                                                                {...register("nomCandidate", { required: true })}
                                                                color='success'
                                                                id="outlined-basic"
                                                                label={"Nom"}
                                                                type={"text"} variant="filled"
                                                                fullWidth
                                                                size='small'
                                                            />
                                                            <TextField
                                                                {...register("prenomCandidate", { required: true })}
                                                                color='success'
                                                                id="outlined-basic"
                                                                label={"Prénom"}
                                                                type={"text"} variant="filled"
                                                                fullWidth
                                                                size='small'
                                                            />
                                                        </Box>
                                                        <br />
                                                        <Box display={'flex'} gap={2}>
                                                            <TextField
                                                                {...register("domaine", { required: true })}
                                                                color='success'
                                                                id="outlined-basic"
                                                                label={"Domaine"}
                                                                type={"text"} variant="filled"
                                                                fullWidth
                                                                size='small'
                                                            />
                                                            <TextField
                                                                {...register("profilCandidate", { required: true })}
                                                                color='success'
                                                                id="outlined-basic"
                                                                label={"Profile"}
                                                                type={"text"} variant="filled"
                                                                fullWidth
                                                                size='small'
                                                            />
                                                        </Box>
                                                        <br />
                                                        <TextField
                                                            {...register("motivLetter", { required: true })}
                                                            color='success'
                                                            id="outlined-basic"
                                                            label={"Lettre de motivation"}
                                                            multiline
                                                            rows={5}
                                                            type={""} variant="filled"
                                                            fullWidth
                                                            size='small'
                                                        />
                                                    </Box>
                                                    <hr style={{ visibility: "hidden" }} />
                                                    <Box>
                                                        {/* 
                                                        <Dragger id='file' onChange={(e) => { convertFile(e as any) }} {...prop} accept='.pdf'>
                                                            <p className="ant-upload-drag-icon">
                                                                <Add />
                                                            </p>
                                                            <p className="ant-upload-text">{'Plassez votre CV (Fichier PDF)'}</p>
                                                            <p className="ant-upload-hint">
                                                                Vous avez également la possibilité de clisser(Drag-end-drop) votre CV pour le plasser !
                                                            </p>
                                                        </Dragger>
                                                        */}
                                                        <label htmlFor="">Votre CV</label><br /><br />

                                                        <input accept='application/pdf' className='btn-file' type="file" name="" onChange={(e) => { convertFile(e as any) }} id="" />
                                                        {/* <UploadFile register={register("cvCandidate")} text={'Plassez votre CV (Fichier PDF)'} /> */}
                                                    </Box>
                                                </Box>
                                                <br /><br />
                                                <Button
                                                    size='large'
                                                    variant='contained'
                                                    fullWidth
                                                    color='success'
                                                    disabled={load}
                                                    endIcon={
                                                        load ? <CircularProgress size={"20px"} color='inherit' /> : ""
                                                    }
                                                    className='btn-submit'
                                                    type='submit'
                                                >Soumettre</Button>
                                            </form>
                                        </Spin>
                                    </Box>
                                </Box>
                            </Container>
                            <Box display={{ xs: 'none', sm: 'contents' }}>
                                <img style={{ width: "5%", marginRight: "2%" }} draggable={false} src={EllipseDroite} alt="" />
                            </Box>
                        </Box>
                        <br /><br /><br />
                    </>
            }

            <Footer />
        </div>
    )
}
export default Postule