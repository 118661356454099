import React from 'react'
import File from "../Fichiers/404.svg";
import DrawerAppBar from '../Components/DrawerAppBar';
import Menus from '../Components/Menus';
import { Box, Button, Container } from '@mui/material';
import Footer from '../Components/Footer';
import EllipseGauche from "../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../Fichiers/Ellipse 201.svg"
import { useNavigate } from 'react-router-dom';

interface Props { }

function NotFound(props: Props) {
    const navigate = useNavigate()

    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <br /><br /><br />
            <Box display={"flex"}>
                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseGauche} alt="" />
                <hr style={{opacity: 0}} />
                <center>
                    <img width={"100%"} src={File} alt="" /> <br />
                    <Button onClick={()=>{navigate("/")}} color='warning' variant='contained' style={{boxShadow: "none"}}>Retour à l'accueil</Button>
                </center>
                <hr style={{opacity: 0}} />
                <img style={{ width: "5%", marginRight: "2%" }} draggable={false} src={EllipseDroite} alt="" />
            </Box>
            <br /><br /><br />
            <Footer />
        </div>
    )
}

export default NotFound
