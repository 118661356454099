import Footer from '../../Components/Footer'
import { useTranslation } from 'react-i18next'
import { Box, Container } from '@mui/material';
import { Variants, motion } from "framer-motion"
import DrawerAppBar from '../../Components/DrawerAppBar';
import Menus from '../../Components/Menus';
import TitrePage from '../../Components/TitrePage';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import EllipseGauche from "../../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../../Fichiers/Ellipse 201.svg"
import illution_4 from "../../Fichiers/illution_4.svg";

interface Props { }

function PretEntreprise(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [load, setLoad] = useState(false);


    const scroll: Variants = {
        offscreen: {
            x: 80,
            opacity: .3
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };


    return (
        <div style={{ backgroundColor: "inherit" }}>
            <DrawerAppBar /><br />
            <Menus />
            <TitrePage titre={'PRET AUX ENTREPRISE'} lien='Accueil / Prêt / Prêt aux Entreprises' />
            <br /><br /><br /><br /><br /><br /><br /><br />



            <Box display={"flex"} lineHeight={1.6} textAlign={"justify"}>
                <img style={{ width: "5%", marginRight: "2%" }} draggable={false} src={EllipseGauche} alt="" />

                <Container maxWidth='lg'>

                    <Box>

                        <Box textAlign={"justify"}>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <img width={"100%"} src={illution_4} alt="" /><br />

                            </motion.div>
                            <label style={{ fontSize: 'xx-large', fontWeight: "bold", color: "#40805C" }} htmlFor="">{t("PRET_AUX_ENTREPRISES.texte.partie1").toUpperCase()}</label>
                            <br />

                        </Box>
                    </Box>
                    <br />

                    <br /><br />
                    <Box >
                        <img width={"50%"} src={illution_4} alt="" /> <br />
                        <Box width={20} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >

                            <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C", width: "100%" }} htmlFor="">
                                {t("PRET_AUX_ENTREPRISES.texte.partie2").toUpperCase()}
                            </label>
                        </motion.div>
                    </Box>
                    <br />
                    <Box display={{ xs: 'block', sm: 'flex' }} gap={20}>
                        <Box>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' htmlFor="">
                                    {t("PRET_AUX_ENTREPRISES.texte.partie3")}
                                </label>
                            </motion.div>
                        </Box>
                        <Box>
                            <motion.div
                                whileHover={{ scale: 1, rotate: 1 }}
                                whileTap={{
                                    scale: 1,
                                    rotate: -1,
                                }}
                            >
                                <img style={{ borderRadius: "10%" }} width={400} src={"https://avenir-gendarmerie.org/wp-content/uploads/2019/11/compte-epargne.jpg"} alt="" />
                            </motion.div>
                        </Box>
                    </Box>

                    <Box >
                        <img width={"50%"} src={illution_4} alt="" /> <br />
                        <Box width={20} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >

                            <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C", width: "100%" }} htmlFor="">
                                {t("PRET_AUX_ENTREPRISES.texte.partie4").toUpperCase()}
                            </label>
                        </motion.div>
                    </Box>
                    <br />
                    <Box display={{ xs: 'block', sm: 'flex' }} gap={20}>
                        <Box>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' style={{ width: "30%", textAlign: "justify", fontSize: "large" }} htmlFor="">
                                    {t("PRET_AUX_ENTREPRISES.texte.partie5")}
                                </label><br />
                            </motion.div>
                        </Box>
                        <Box>
                            <motion.div
                                whileHover={{ scale: 1, rotate: 1 }}
                                whileTap={{
                                    scale: 1,
                                    rotate: -1,
                                }}
                            >
                                <img style={{ borderRadius: "10%" }} width={400} src={"https://avenir-gendarmerie.org/wp-content/uploads/2019/11/compte-epargne.jpg"} alt="" />
                            </motion.div>
                        </Box>
                    </Box>
                    <br /><br />
                    <Box >
                        <img width={"50%"} src={illution_4} alt="" /> <br />
                        <Box width={20} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >

                            <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C", width: "100%" }} htmlFor="">
                                {t("PRET_AUX_ENTREPRISES.texte.partie6").toUpperCase()}
                            </label>
                        </motion.div>
                    </Box>
                    <br />
                    <Box display={{ xs: 'block', sm: 'flex' }} gap={20}>
                        <Box>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' htmlFor="">
                                    {t("PRET_AUX_ENTREPRISES.texte.partie7")}
                                </label>
                            </motion.div>
                        </Box>
                        <Box>
                            <motion.div
                                whileHover={{ scale: 1, rotate: 1 }}
                                whileTap={{
                                    scale: 1,
                                    rotate: -1,
                                }}
                            >
                                <img style={{ borderRadius: "10%" }} width={400} src={"https://avenir-gendarmerie.org/wp-content/uploads/2019/11/compte-epargne.jpg"} alt="" />
                            </motion.div>
                        </Box>
                    </Box>


















                    <br /><br />
                    <Box >
                        <img width={"50%"} src={illution_4} alt="" /> <br />
                        <Box width={20} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >

                            <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C", width: "100%" }} htmlFor="">
                                {t("PRET_AUX_ENTREPRISES.texte.partie8").toUpperCase()}
                            </label>
                        </motion.div>
                    </Box>
                    <br />
                    <Box display={{ xs: 'block', sm: 'flex' }} gap={20}>
                        <Box>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' htmlFor="">
                                    {t("PRET_AUX_ENTREPRISES.texte.partie9")}
                                </label>
                            </motion.div>
                        </Box>
                        <Box>
                            <motion.div
                                whileHover={{ scale: 1, rotate: 1 }}
                                whileTap={{
                                    scale: 1,
                                    rotate: -1,
                                }}
                            >
                                <img style={{ borderRadius: "10%" }} width={400} src={"https://avenir-gendarmerie.org/wp-content/uploads/2019/11/compte-epargne.jpg"} alt="" />
                            </motion.div>
                        </Box>
                    </Box>



                    <br /><br />
                    <Box >
                        <img width={"50%"} src={illution_4} alt="" /> <br />
                        <Box width={20} />
                        <motion.div
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={scroll}
                            className='produitsEtServices'
                        >

                            <label className='textgray' style={{ fontSize: 'x-large', fontWeight: "bold", color: "#40805C", width: "100%" }} htmlFor="">
                                {t("PRET_AUX_ENTREPRISES.texte.partie10").toUpperCase()}
                            </label>
                        </motion.div>
                    </Box>
                    <br />
                    <Box display={{ xs: 'block', sm: 'flex' }} gap={20}>
                        <Box>
                            <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.8 }}
                                variants={scroll}
                                className='produitsEtServices'
                            >
                                <label className='textgray' htmlFor="">
                                    {t("PRET_AUX_ENTREPRISES.texte.partie11")}
                                </label><br /><br />

                                <label className='textgray' htmlFor="">
                                    {t("PRET_AUX_ENTREPRISES.texte.partie12")}
                                </label>
                            </motion.div>
                        </Box>
                        <Box>
                            <motion.div
                                whileHover={{ scale: 1, rotate: 1 }}
                                whileTap={{
                                    scale: 1,
                                    rotate: -1,
                                }}
                            >
                                <img style={{ borderRadius: "10%" }} width={400} src={"https://avenir-gendarmerie.org/wp-content/uploads/2019/11/compte-epargne.jpg"} alt="" />
                            </motion.div>
                        </Box>
                    </Box>

                    <img width={"50%"} src={illution_4} alt="" />
                    <br /><br />
                </Container>
                <img style={{ width: "5%", marginLeft: "2%" }} draggable={false} src={EllipseDroite} alt="" />
            </Box>

            <Footer />
        </div>
    )
}
export default PretEntreprise