import { Carousel } from 'antd'
import { Variants, motion } from 'framer-motion'
import ActualiteOuEvenement from './ActualiteOuEvenement'
import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import illution_4 from "../Fichiers/illution_4.svg";
import { useEffect, useState } from 'react'
import Post from '../Models/Post'
import axios from 'axios'
import env from 'react-dotenv'
import Data from "../Data/.json";

interface Props { }

function NosActualitesEtEvenements(props: Props) {
    const { } = props
    const { t, i18n } = useTranslation();
    const [datas, setDatas] = useState<Post[]>([])
    const [loading, setLoading] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(true);

    const settings = {
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />
    }
    const scroll: Variants = {
        offscreen: {
            y: 80,
            opacity: .3
        },
        onscreen: {
            y: 10,
            opacity: 1,
            transition: {
                bounce: 0.6,
                duration: 1
            }
        }
    };

    if (pageLoaded) {
        setPageLoaded(false)
        LoadDatas()
    }

    function LoadDatas() {
        setLoading(true)

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: env.API_BASE_URL + Data.API_LINKs.ReadAllPosts,
            headers: {
                'elmt1': 'AllPost',
                'Content-Type': 'application/json, application/json',
                'Authorization': 'Bearer ' + env.API_TOKEN,
            }
        };

        axios.request(config)
            .then((response) => {
                if (response.data.StatutCode == 200) {
                    setDatas(response.data.Result as Post[])
                }
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            });

    }

    return (
        <div>
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            >
                <Box textAlign={"justify"} >
                    <h1>{t("TITRE.NosActualiteEtEvenement")}</h1>
                    <img width={"100%"} draggable={false} src={illution_4} alt="" />
                    <br /><br />
                    <label className='textgray'>{t("SOUS_TITRE.NosActualiteEtEvenement")}</label>
                    <br /><br />
                </Box>
            </motion.div>
            <br />
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                variants={scroll}
                className='produitsEtServices'
            >

                <center>
                    {loading ?
                        <CircularProgress color='success' />
                        :
                        datas.length == 0 ?
                            <Typography>Aucune actualité publié pour le moment</Typography>
                            :
                            <>
                                <Box display={{ xs: "none", sm: "block" }}>

                                    <Carousel slidesToShow={datas.length >= 4 ? 4: datas.length} dots={false} arrows {...settings} autoplay dotPosition='bottom'>
                                        {datas.map((p: Post) => (
                                            <ActualiteOuEvenement post={p} />
                                        ))}
                                    </Carousel>
                                </Box>
                                <Box display={{ xs: "block", sm: "none" }}>

                                    <Carousel slidesToShow={1} dots={false} arrows {...settings} autoplay dotPosition='bottom'>
                                        {datas.map((p: Post) => (
                                            <ActualiteOuEvenement post={p} />
                                        ))}
                                    </Carousel>
                                </Box>
                            </>
                    }
                </center>
            </motion.div>
        </div>
    )
}

export default NosActualitesEtEvenements
