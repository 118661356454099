import React from 'react';
import type { MenuProps } from 'antd';
import { Button, Dropdown } from 'antd';
import ListeMenu from "../Database/ListeMenu.json";
import { Link, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { ArrowDropDownCircleRounded, ArrowDropDownOutlined, ArrowDropDownRounded, ArrowRightRounded, DownhillSkiing } from '@mui/icons-material';


function Nav() {
    const navigate = useNavigate()

    const handleClick = (event: React.MouseEvent<HTMLDivElement>, link: string) => {
        navigate(link)
        const anchor =
            (
                (event.target as HTMLDivElement).ownerDocument || document
            ).querySelector(link.includes("#") ? ("#" + link.split("#")[1]): "#back-to-top-anchor");

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }
    };
    return (
        <>
            {ListeMenu.map((m) => {
                var items: MenuProps['items'] = [];

                if (m.submenu.length == 0) {
                    return (
                        <Button onClick={() => { navigate(m.url) }}>{m.title}</Button>
                    )
                }
                else {
                    m.submenu.map((sm, index) => {
                        if (sm.url != "") {
                            items?.push({
                                key: index,
                                label: (
                                    <Link rel="noopener noreferrer" to={sm.url}>
                                        {sm.title}
                                    </Link>
                                ),
                            })
                        }
                        else {
                            items?.push({
                                key: index,
                                type: "group",
                                label: (
                                    <>
                                        <Box display={"flex"}>
                                            <Box display={"flex"}>
                                                <legend style={{ color: "#FFA412", fontWeight: "bold" }}>{sm.title} </legend>
                                                <ArrowRightRounded />
                                            </Box>
                                            {
                                                sm.elements.map((el, index) => {
                                                    return (
                                                        <Box onClick={(e) => { handleClick(e, el.url) }} className='link' display={'flex'}>
                                                            <Link style={{ padding: "5px", marginLeft: "2px", color: "black", marginTop: "-5px" }} rel="noopener noreferrer" to={el.url}>
                                                                {el.title}
                                                            </Link>
                                                        </Box>
                                                    );
                                                })
                                            }
                                        </Box>
                                        <hr color='gray' style={{ opacity: "10%" }} />
                                    </>
                                ),
                            })
                        }
                    })
                }
                return (
                    <Dropdown menu={{ items }} placement="bottomLeft" arrow>
                        <Button icon={<ArrowDropDownCircleRounded style={{verticalAlign: "middle", width: "15px", opacity: "70%", marginTop: "-3px" }} />}>{m.title}</Button>
                    </Dropdown>
                )
            })}
        </>
    )

}

export default Nav;