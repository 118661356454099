import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { motion } from "framer-motion";
import DrawerAppBar from './DrawerAppBar';
import Menus from './Menus';
import TitrePage from './TitrePage';
import Footer from './Footer';
import { Box, Card, CardActions, CardContent, CardMedia, Container } from '@mui/material';
import { Carousel } from 'antd';
import ActualiteOuEvenement from './ActualiteOuEvenement';
import { ArrowLeft, ArrowRight, Fullscreen, FullscreenExit } from '@mui/icons-material';
import EllipseGauche from "../Fichiers/Ellipse 200.svg"
import EllipseDroite from "../Fichiers/Ellipse 201.svg"
import Post from '../Models/Post';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function OpenActualiteOuEvement(props: { post: Post }) {
    const [open, setOpen] = React.useState(false);
    const [full, setFull] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const settings = {
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />
    }

    return (
        <React.Fragment>
            <motion.button
                onClick={handleClickOpen}
                whileHover={{
                    scale: 1.2,
                    transition: { duration: 1 },
                }}
                whileTap={{ scale: 0.9 }}
                className='btn2'>En savoir plus</motion.button>
            <Dialog
                fullScreen={full}
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <TitrePage titre={'ACTUALITE ET EVENEMENT'} lien={props.post.TitrePost} />
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

                <Box display={"flex"}>
                    {
                        full ?
                            <Box display={{ xs: 'none', sm: 'contents' }}>
                                <img style={{ width: "5%" }} draggable={false} src={EllipseGauche} alt="" />
                            </Box>
                            :
                            ""
                    }
                    <Container maxWidth="lg">
                        <center>
                            <Card sx={{ maxWidth: "90%" }} className='produitCard'>

                                <Typography style={{ fontSize: "100%", fontWeight: "bold" }} gutterBottom variant="h6" >
                                    {props.post.soustitre}
                                </Typography>
                                <CardMedia
                                    sx={{ height: 300 }}
                                    image={props.post.image}
                                />
                                <CardContent>
                                    <Typography textAlign={"justify"} variant="body2" color="text.secondary">
                                        {props.post.description}
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ backgroundColor: "#0D6638" }}>
                                    <Typography color={"white"}>{props.post.dateEnv}</Typography>
                                </CardActions>
                            </Card>
                        </center>
                    </Container>
                    {
                        full ?

                            <Box display={{ xs: 'none', sm: 'contents' }}>
                                <img style={{ width: "5%", marginRight: "2%" }} draggable={false} src={EllipseDroite} alt="" />
                            </Box>
                            :
                            ""}
                </Box>

                <br /><br /><br />

                <IconButton onClick={handleClose} size="large" style={{ position: "fixed", padding: "20px", margin: "20px", background: '#FFA412' }}><CloseIcon /></IconButton>
                <IconButton onClick={() => { setFull(!full) }} size="large" style={{ border: "3px white solid", position: "fixed", padding: "10px", margin: "10px", top: "10%", background: '#0D6638' }}>{full ? <FullscreenExit color='warning' /> : <Fullscreen color='warning' />}</IconButton>
            </Dialog>
        </React.Fragment>
    );
}